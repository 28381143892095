<!-- @format -->

<template>
  <div class="filmwelt-social" :class="$mq">
    <div class="filmwelt-social__wrapper">
      <a
        v-if="youtube"
        class="filmwelt-social__youtube"
        href="https://www.youtube.com/channel/UCOab08IXF9FgucvLoBO3ltg"
        target="_blank"
      >
        <youtube />
      </a>
      <ul :class="$mq">
        <mq-layout mq="lg+">
          <li class="share" v-if="$config.kinoservice.share !== ''">
            <a href="#" @mouseover="hover = true" @mouseleave="hover = false"
              >Teilen</a
            >
            <div
              class="share__btns"
              :class="{ hovered: hover }"
              @mouseover="hover = true"
              @mouseleave="hover = false"
            >
              <a
                target="_blank"
                :href="`https://www.facebook.com/sharer/sharer.php?u=${$config.url}`"
                class="share__item share__fb"
                ><img src="@/assets/images/social/fb_plain.png" alt="" />
              </a>
              <a
                :href="`https://twitter.com/intent/tweet?text=${$config.twitter.text}`"
                target="_blank"
                class="share__item share__twitter"
              >
                <img src="@/assets/images/social/twitter_plain.png" alt="" />
              </a>
            </div>
          </li>
        </mq-layout>
      </ul>
      <a
        v-if="instagram"
        class="filmwelt-social__instagram"
        :href="$config.instagram.link"
        target="_blank"
      >
        <instagram />
      </a>
      <a
        v-if="facebook"
        class="filmwelt-social__facebook"
        :href="$config.facebook.link"
        target="_blank"
      >
        <facebook-box />
      </a>
    </div>
  </div>
</template>

<script>
  import FacebookBox from 'vue-material-design-icons/Facebook.vue'
  import Youtube from 'vue-material-design-icons/Youtube.vue'
  import Instagram from 'vue-material-design-icons/Instagram.vue'
  export default {
    components: {
      FacebookBox,
      Youtube,
      Instagram,
    },
    data() {
      return {
        hover: false,
      }
    },
    props: {
      facebook: {
        type: Boolean,
        default: true,
      },
      youtube: {
        type: Boolean,
        default: true,
      },
      instagram: {
        type: Boolean,
        default: true,
      },
      whatsapp: {
        type: Boolean,
        default: true,
      },
    },
  }
</script>

<style lang="scss">
  .filmwelt-social {
    $self: &;
    &__wrapper {
      display: flex;
      flex-direction: row;
      ul {
        margin: 0;
        padding: 0;
        text-align: center;
        list-style-type: none;
        line-height: $header-lineheight;
        display: flex;
        &.sm,
        &.md {
          flex-direction: column;
        }

        .hovered {
          transform: translateY($header-height);
        }

        .share {
          position: relative;
          &__btns {
            position: absolute;
            padding-left: 10px;
            right: 82px;
            top: -59px;
            height: $header-height;
            display: flex;
            flex-direction: row;
            transition: transform 0.3s ease-in-out;
            width: 120px;
            .share__item {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
            }
            .share__fb {
              background-color: $facebook;
              &:hover {
                background-color: darken($facebook, 5%);
              }
            }
            .share__twitter {
              background-color: $twitter;
              &:hover {
                background-color: darken($twitter, 5%);
              }
            }
          }
        }
        li {
          padding-right: 14px;
          height: $header-height;
          display: inline-block;
          text-align: left;
          color: white;
          &.sm,
          &.md {
            height: auto;
          }
          a {
            text-decoration: none;
            text-transform: uppercase;
            height: $header-height;
            line-height: $header-height;
            color: white;
            font-weight: 500;
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 16px;
            $max_font: 18px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
            &.sm,
            &.md {
              font-weight: 600;
              margin-bottom: 10px;
              $min_width: 300px;
              $max_width: 1023px;
              $min_font: 20px;
              $max_font: 38px;
              @include fluid-type($min_width, $max_width, $min_font, $max_font);
            }
          }
          &:not(:last-child) {
            padding-right: $header-link-padding;
          }
        }
      }
      #{$self}__whatsapp {
        &.lg,
        &.xl,
        &.xxl,
        &.xxxxl {
          display: none;
        }
      }
      #{$self}__facebook,
      #{$self}__youtube,
      #{$self}__instagram,
      #{$self}__whatsapp {
        position: relative;
        text-transform: uppercase;
        height: $header-height;
        line-height: $header-height;
        padding-right: 7px;
        color: $header-link;
        font-weight: $header-fontweight;
        cursor: pointer;
        $min_width: 1024px;
        $max_width: 2560px;
        $min_font: 22px;
        $max_font: $header-fontsize + 10;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
        svg {
          fill: $header-link;
        }
      }
    }
    &.sm {
      color: white;
    }
  }
</style>
